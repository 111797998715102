/* ----------------- Start Document ----------------- */
(function($) {
  "use strict";

  $(document).ready(function() {
    /*----------------------------------------------------*/
    /*  Sticky Header
	/*----------------------------------------------------*/
    //$( "#header" ).not( "#header-container.header-style-2 #header" ).clone(true).addClass('cloned unsticky').insertAfter( "#header" );
    $("#navigation.style-2")
      .clone(true)
      .addClass("cloned unsticky")
      .insertAfter("#navigation.style-2");

    // Logo for header style 2
    $("#logo .sticky-logo")
      .clone(true)
      .prependTo("#navigation.style-2.cloned ul#responsive");

    // sticky header script
    var headerOffset = $("#header-container").height() * 2; // height on which the sticky header will shows

    $(window).scroll(function() {
      if ($(window).scrollTop() >= headerOffset) {
        $("#header.cloned")
          .addClass("sticky")
          .removeClass("unsticky");
        $("#navigation.style-2.cloned")
          .addClass("sticky")
          .removeClass("unsticky");
      } else {
        $("#header.cloned")
          .addClass("unsticky")
          .removeClass("sticky");
        $("#navigation.style-2.cloned")
          .addClass("unsticky")
          .removeClass("sticky");
      }
    });

    /*----------------------------------------------------*/
    /* Advanced Search Button
	/*----------------------------------------------------*/
    $(".adv-search-btn").on("click", function(e) {
      if ($(this).is(".active")) {
        $(this).removeClass("active");
        $(".main-search-container").removeClass("active");
        setTimeout(function() {
          $("#map-container.homepage-map").removeClass("overflow");
        }, 0);
      } else {
        $(this).addClass("active");
        $(".main-search-container").addClass("active");
        setTimeout(function() {
          $("#map-container.homepage-map").addClass("overflow");
        }, 400);
      }

      e.preventDefault();
    });

    /*----------------------------------------------------*/
    /*  Inline CSS replacement for backgrounds etc.
	/*----------------------------------------------------*/
    function inlineCSS() {
      // Common Inline CSS
      $(
        ".some-classes, section.fullwidth, .img-box-background, .flip-banner, .property-slider .item, .fullwidth-property-slider .item, .fullwidth-home-slider .item, .address-container"
      ).each(function() {
        var attrImageBG = $(this).attr("data-background-image");
        var attrColorBG = $(this).attr("data-background-color");

        if (attrImageBG !== undefined) {
          $(this).css("background-image", "url(" + attrImageBG + ")");
        }

        if (attrColorBG !== undefined) {
          $(this).css("background", "" + attrColorBG + "");
        }
      });
    }

    // Init
    inlineCSS();

    // Slide to anchor
    $("#titlebar .listing-address").on("click", function(e) {
      e.preventDefault();

      $("html, body").animate(
        {
          scrollTop: $($.attr(this, "href")).offset().top - 40
        },
        600
      );
    });

    /*----------------------------------------------------*/
    /*  Tooltips
	/*----------------------------------------------------*/

    $(".tooltip.top").tipTip({
      defaultPosition: "top"
    });

    $(".tooltip.bottom").tipTip({
      defaultPosition: "bottom"
    });

    $(".tooltip.left").tipTip({
      defaultPosition: "left"
    });

    $(".tooltip.right").tipTip({
      defaultPosition: "right"
    });

    /*----------------------------------------------------*/
    /*  Search Type Buttons
	/*----------------------------------------------------*/
    function searchTypeButtons() {
      // Radio attr reset
      $('.search-type label.active input[type="radio"]').prop("checked", true);

      // Positioning indicator arrow
      var buttonWidth = $(".search-type label.active").width();
      var arrowDist = $(".search-type label.active").position().left;
      $(".search-type-arrow").css("left", arrowDist + buttonWidth / 2);

      $(".search-type label").on("change", function() {
        $('.search-type input[type="radio"]')
          .parent("label")
          .removeClass("active");
        $('.search-type input[type="radio"]:checked')
          .parent("label")
          .addClass("active");

        // Positioning indicator arrow
        var buttonWidth = $(".search-type label.active").width();
        var arrowDist = $(".search-type label.active").position().left;

        $(".search-type-arrow").css({
          left: arrowDist + buttonWidth / 2,
          transition: "left 0.4s cubic-bezier(.87,-.41,.19,1.44)"
        });
      });
    }

    // Init
    if ($(".main-search-form").length) {
      searchTypeButtons();
    }

    /*  Custom Input With Select
    /*----------------------------------------------------*/
    $(".select-input").each(function() {
      // Unit character
      var fieldUnit = $(this)
        .children("input")
        .attr("data-unit");
      $(this)
        .children("input")
        .before('<i class="data-unit">' + fieldUnit + "</i>");
    });

    /*----------------------------------------------------*/
    /*  Searh Form More Options
    /*----------------------------------------------------*/
    $(".more-search-options-trigger").on("click", function(e) {
      e.preventDefault();
      $(".more-search-options, .more-search-options-trigger").toggleClass(
        "active"
      );
      $(".more-search-options.relative").animate(
        { height: "toggle", opacity: "toggle" },
        300
      );
    });

    /*----------------------------------------------------*/
    /*  Like Icon Trigger
    /*----------------------------------------------------*/
    $(".like-icon, .widget-button").on("click", function(e) {
      e.preventDefault();
      $(this).toggleClass("liked");
      $(this)
        .children(".like-icon")
        .toggleClass("liked");
    });

    /*----------------------------------------------------*/
    /*  Show More Button
    /*----------------------------------------------------*/
    $(".show-more-button").on("click", function(e) {
      e.preventDefault();
      $(".show-more").toggleClass("visible");
    });

    /*----------------------------------------------------*/
    /*  Back to Top
	/*----------------------------------------------------*/
    var pxShow = 600; // height on which the button will show
    var fadeInTime = 300; // how slow / fast you want the button to show
    var fadeOutTime = 300; // how slow / fast you want the button to hide
    var scrollSpeed = 500; // how slow / fast you want the button to scroll to top.

    $(window).scroll(function() {
      if ($(window).scrollTop() >= pxShow) {
        $("#backtotop").fadeIn(fadeInTime);
      } else {
        $("#backtotop").fadeOut(fadeOutTime);
      }
    });

    /*----------------------------------------------------*/
    /*  Magnific Popup
    /*----------------------------------------------------*/
    $("body").magnificPopup({
      type: "image",
      delegate: "a.mfp-gallery",

      fixedContentPos: true,
      fixedBgPos: true,

      overflowY: "auto",

      closeBtnInside: false,
      preloader: true,

      removalDelay: 0,
      mainClass: "mfp-fade",

      gallery: { enabled: true },

      image: {
        titleSrc: function(item) {
        return item.el.find('.image-tag').text();
      }
    }
    });

    $(".popup-with-zoom-anim").magnificPopup({
      type: "inline",

      fixedContentPos: false,
      fixedBgPos: true,

      overflowY: "auto",

      closeBtnInside: true,
      preloader: false,

      midClick: true,
      removalDelay: 300,
      mainClass: "my-mfp-zoom-in"
    });

    $(".mfp-image").magnificPopup({
      type: "image",
      closeOnContentClick: true,
      mainClass: "mfp-fade",
      image: {
        verticalFit: true
      }
    });

    $(".open-mfp").click(function() {
      $("body").magnificPopup("open");
    });

    /*----------------------------------------------------*/
    /*  Submit Property
    /*----------------------------------------------------*/

    // Tooltip
    $(".tip").each(function() {
      var tipContent = $(this).attr("data-tip-content");
      $(this).append('<div class="tip-content">' + tipContent + "</div>");
    });

    /*----------------------------------------------------*/
    /*  Notifications
	/*----------------------------------------------------*/

    $("a.close")
      .removeAttr("href")
      .on("click", function() {
        $(this)
          .parent()
          .fadeOut(200);
      });

    // ------------------ End Document ------------------ //
  });
})(this.jQuery);

/*----------------------------------------------------*/
/*  Listing Layout Switcher
/*----------------------------------------------------*/
function gridLayoutSwitcher() {
  var listingsContainer = $(".listings-container");

  // if list layout is active
  function listLayout() {
    $(listingsContainer).each(function() {
      $(this).removeClass("grid-layout grid-layout-three");
      $(this).addClass("grid-layout-three");
    });

    $(".listing-item").each(function() {
      var listingContent = $(this)
        .find(".listing-content")
        .height();
      $(this)
        .find(".listing-img-container img")
        .css("height", "" + listingContent + "");
      $(this)
        .find(".listing-img-container")
        .css("height", "" + listingContent + "");
    });
  }
}
