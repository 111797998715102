var myExtObject = (function() {
  return {
    listLayout: function() {
      setTimeout(function() {
        gridLayoutSwitcher();
      }, 50);
    },
    tooltip: function() {
      setTooltips();
    },
    toggle: function() {
      setToggle();
    },
    openMfp: function() {
      openMfp();
    }
  };
})(myExtObject || {});

function gridLayoutSwitcher() {
  if ($(".listing-item").length == 0) {
    setTimeout(function() {
      gridLayoutSwitcher();
    }, 50);
  } else {
    var listingsContainer = $(".listings-container");
    // if list layout is active
    function listLayout() {
      $(listingsContainer).each(function() {
        $(this).removeClass("grid-layout grid-layout-three");
        $(this).addClass("grid-layout-three");
      });

      $(".listing-item").each(function() {
        var listingContent = $(this)
          .find(".listing-content")
          .height();
        $(this)
          .find(".listing-img-container img")
          .css("height", "" + listingContent + "");
        $(this)
          .find(".listing-img-container")
          .css("height", "" + listingContent + "");
      });
    }
    listLayout();
  }
}

function setTooltips() {
  // Tooltip
  setTimeout(function() {
    $(".tip").each(function() {
      var tipContent = $(this).attr("data-tip-content");
      if ($(this).find(".tip-content").length == 0)
        $(this).append('<div class="tip-content">' + tipContent + "</div>");
    });
  }, 250);
}

function setToggle() {
  $(".toggle-container").hide();

  $(".trigger, .trigger.opened").on("click", function(a) {
    $(this).toggleClass("active");
    a.preventDefault();
  });

  $(".trigger").on("click", function() {
    $(this)
      .next(".toggle-container")
      .slideToggle(300);
  });

  $(".trigger.opened")
    .addClass("active")
    .next(".toggle-container")
    .show();
}

function openMfp() {
  $("body").magnificPopup("open");
}
